import { GUID } from "@faro-lotv/foundation";
import { RootState } from "./store";

/**
 * @returns the elements to show the pose for in the 3d view
 * @param state root state
 */
export function selectElementToShowPoseFor(state: RootState): GUID[] {
  return state.selection.elementsPose;
}

/**
 * @returns the elements to show the axis-aligned bounding box for in the 3d view
 * @param state root state
 */
export function selectElementToShowBoundingBoxFor(state: RootState): GUID[] {
  return state.selection.elementsBoundingBox;
}

/**
 * @param id of the element to check
 * @returns if the pose of this element is visible in the 3d view
 */
export function selectHasPoseVisible(id: GUID) {
  return (state: RootState) => state.selection.elementsPose.includes(id);
}

/**
 * @returns the element to show the 3d data for in the 3d view
 * @param state root state
 */
export function selectElementToShowDataFor(state: RootState): GUID[] {
  return state.selection.elementsData;
}

/**
 * @param id of the element to check
 * @returns if the axis-aligned bounding box of this element is visible in the 3d view
 */
export function selectHasBoundingBoxVisible(id: GUID) {
  return (state: RootState) => state.selection.elementsBoundingBox.includes(id);
}

/**
 * @param id of the element to check
 * @returns if the 3d data of this element is visible in the 3d view
 */
export function selectHasDataVisible(id: GUID) {
  return (state: RootState) => state.selection.elementsData.includes(id);
}

/**
 * @returns the id of the selected element in the tree
 * @param state root state
 */
export function selectSelected(state: RootState): GUID | undefined {
  return state.selection.selected;
}
