import { IElement } from "@faro-lotv/ielement-types";
import { Wireframe } from "@react-three/drei";

export type ElementBoundingBoxRendererProps = {
  element: IElement;
};

/** @returns a box representing the global axis-aligned bounding box of the element. */
export function ElementBoundingBoxRenderer({
  element,
}: ElementBoundingBoxRendererProps): JSX.Element | null {
  const min = element.boundingBox?.min;
  const max = element.boundingBox?.max;

  if (!min || !max) return null;

  const size = {
    x: max.x - min.x,
    y: max.y - min.y,
    z: max.z - min.z,
  };

  // Only render the bounding box, if there is any volume.
  if (size.x * size.y * size.z <= 0) return null;

  // Flipping z-coordinate due to the change of coordinate systems.
  // This should be equivalent to the preScaleZ flip performed on the ProjectRoot in the world-transform-cache.
  const center = {
    x: size.x / 2 + min.x,
    y: size.y / 2 + min.y,
    z: -(size.z / 2 + min.z),
  };

  return (
    <group position={[center.x, center.y, center.z]}>
      <mesh>
        <boxGeometry args={[size.x, size.y, size.z]} />
        <meshBasicMaterial transparent opacity={0.5} color="lightgray" />
        <Wireframe />
      </mesh>
    </group>
  );
}
